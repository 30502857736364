import { useLoadingFlag } from '@karlrwjohnson/libkarl/esm/hooks/useLoadingFlag.js';
import type { ReactElement, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import type { ButtonVariant } from 'react-bootstrap/types';
import { useNavigate } from 'react-router';
import { useStickyModalFooterScrollDetector } from '../hooks/useStickyModalFooterScrollDetector.js';
import type { SetError } from '../utils/useErrorState.js';
import { useErrorState } from '../utils/useErrorState.js';
import { DeleteButtonWithConfirm } from './DeleteButtonWithConfirm.js';

export type SubmitResultAction =
    | { type: 'STAY_OPEN' }
    | { type: 'CLOSE' }
    | { type: 'REDIRECT', location: string };
export const SubmitResultAction = {
    STAY_OPEN: { type: 'STAY_OPEN' as const },
    CLOSE: { type: 'CLOSE' as const },
    redirect(location: string): SubmitResultAction {
        return { type: 'REDIRECT' as const, location };
    }
}

export type Confirm = {
    cancelLabel: string;
    confirmLabel: string;
    confirmVariant: ButtonVariant;
    messageBody?: ReactNode;
    messageTitle: string;
}

export type ModalFormAction = {
    confirm?: Confirm;
    icon?: JSX.Element;
    label: ReactNode;
    onClick: (setError: SetError) => Promise<SubmitResultAction>;
    variant?: ButtonVariant;
}

export function ModalFormActionView(
    {
        confirm,
        formIsLoading,
        icon,
        label,
        onClick,
        processFormAction,
        variant,
    }: ModalFormAction & {
        formIsLoading: boolean;
        processFormAction: (block: (setError: SetError) => Promise<SubmitResultAction>) => Promise<void>;
    }
): ReactElement {
    const { loading: selfIsLoading, doWhileLoading: doWhileSelfIsLoading } = useLoadingFlag();

    const handleClick = useCallback(async () => {
        await doWhileSelfIsLoading(async () => {
            await processFormAction(async (setError: SetError) => {
                // noinspection UnnecessaryLocalVariableJS
                const submitResult = await onClick(setError);
                return submitResult;
            });
        });
    }, [processFormAction, doWhileSelfIsLoading, onClick]);

    if (confirm) {
        return (
            <DeleteButtonWithConfirm
                buttonDisabled={formIsLoading}
                buttonIcon={icon}
                buttonLabel={label}
                buttonVariant={variant}
                cancelButtonLabel={confirm.cancelLabel}
                confirmButtonLabel={confirm.confirmLabel}
                confirmButtonIcon={icon}
                confirmButtonVariant={confirm.confirmVariant}
                confirmMessageBody={confirm.messageBody}
                confirmMessageTitle={confirm.messageTitle}
                onConfirm={handleClick}
                placement="top-end"
            />
        );
    }
    return (
        <Button
            className="d-flex flex-row gap-2 align-items-center"
            disabled={formIsLoading}
            onClick={handleClick}
            variant={variant}
        >
            {selfIsLoading ?
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> :
                icon
            }
            {label}
        </Button>
    );
}

/**
 * Assume that this modal is being rendered by a route and contains a form
 * @constructor
 */
export function ModalFormRoute(
    {
        actions,
        children,
        title,
    }: {
        actions: ModalFormAction[];
        children: ReactNode;
        title: ReactNode;
    }
): JSX.Element {
    
    const { loading: formIsLoading, doWhileLoading } = useLoadingFlag();
    const [editing, setEditing] = useState(true);
    const { errorElement, setError } = useErrorState();
    const [handleExited, setHandleExited] = useState<() => void>();

    const navigate = useNavigate();

    const handleCancel = useCallback(() => {
        setEditing(false);
        setHandleExited(() => () => navigate(-1));
    }, [navigate]);

    const processFormAction = useCallback(async (onAction: (setError: SetError) => Promise<SubmitResultAction>) => {
        try {
            const result = await doWhileLoading(async () => {
                // noinspection UnnecessaryLocalVariableJS
                const submitResult = await onAction(setError);
                return submitResult;
            });

            switch(result.type) {
                case 'CLOSE':
                    setEditing(false);
                    setHandleExited(() => () => navigate(-1));
                    break;
                case 'REDIRECT':
                    setEditing(false);
                    setHandleExited(() => () => navigate(result.location, { replace: true }));
                    break;
            }
        } catch (e) {
            console.error('Unexpected error', e);
            setError('Sorry, an error occurred');
        }
    }, [doWhileLoading, navigate, setError]);

    const { isStickied, setFooterElement } = useStickyModalFooterScrollDetector();

    return (
        <Modal
            onHide={handleCancel}
            onExited={handleExited}
            show={editing}
        >
            <form>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {errorElement}

                    {children}
                </Modal.Body>

                <Modal.Footer
                    className="position-sticky bottom-0 bg-white"
                    ref={setFooterElement}
                    style={{
                        boxShadow: isStickied ? '0 -0.25rem 0.5rem -0.25rem rgba(0,0,0,0.4)' : '',
                        transition: 'box-shadow 200ms',
                    }}
                >
                    <Button
                        onClick={handleCancel}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>

                    <div className="flex-grow-1 flex-shrink-1" />

                    {actions.map((action, index) => (
                        <ModalFormActionView
                            {...action}
                            formIsLoading={formIsLoading}
                            key={index}
                            processFormAction={processFormAction}
                        />
                    ))}
                </Modal.Footer>
            </form>
        </Modal>
    );
}
