import { Badge, Button, Card, ListGroup } from 'react-bootstrap';
import { ClaimedWishIcon, ClaimWishIcon, DuplicateGifts, LoginIcon, NoIdeas } from '../assets/index.js';
import { SiteIcon } from '../assets/SiteIcon.js';
import { useLoginFunction } from './RedirectCallback.js';

export function LoginPage(): JSX.Element {
    const login = useLoginFunction();

    return (
        <>
            <form
                className="d-flex
                    flex-column flex-md-row
                    justify-content-center
                    align-items-center
                    position-relative vw-100
                    pt-5 pb-5
                    bg-dark bg-gradient text-white
                    mb-5
                "
                style={{
                    marginTop: '-1rem', // undo parent mt-3 = 1rem
                    left: 'calc( (100% - 100vw) / 2)',
                }}
            >
                <div className="flex-grow-1 d-flex flex-column align-items-end me-md-5" style={{ flexBasis: 0 }}>
                    <div className="d-flex flex-column align-items-center">
                        <span className="text-muted">
                            <SiteIcon size={200} />
                        </span>

                        <h1 className="mt-3 mb-4">Wishing Well</h1>
                    </div>
                </div>

                <div className="flex-grow-1 ms-md-5" style={{ flexBasis: 0 }}>
                    <div className="d-none d-md-block">
                        <h3 className="mb-5">Share wish lists<br/>with friends and family</h3>
                    </div>
                    <div className="d-flex gap-2">
                        <Button as="a" href="#more-info" variant="light">Learn More</Button>
                        <Button onClick={login} variant="primary"><LoginIcon /> Sign In</Button>
                    </div>
                </div>
            </form>


            <a id="more-info" className="position-relative" style={{ top: '-5rem' }} />
            <h1>What is Wishing Well?</h1>

            <p>
                Wishing well simplifies gift-giving between friends and family.
            </p>

            <div className="d-flex flex-column gap-3 flex-md-row gap-md-5 mb-3">
                <Card className="flex-grow-1" style={{flexBasis: 0}}>
                    <Card.Body>
                        <h2>Manage your Wish List</h2>

                        <p>
                            Add <b>Wishes</b> to your wish list to share your interests with your friends and family.
                            Then, hunt for gift ideas among your loved ones' wish lists!
                        </p>

                        <Card className="p-4 my-4" style={{ backgroundColor: 'rgb(0,0,0,0.01)', boxShadow: '0 0.25rem 0.5rem rgba(0,0,0,0.1) inset' }}>
                            <h3>My Wishes</h3>
                            <ListGroup>
                                <ListGroup.Item>Toy Car</ListGroup.Item>
                                <ListGroup.Item>Puzzles</ListGroup.Item>
                                <ListGroup.Item>Lamp</ListGroup.Item>
                            </ListGroup>
                        </Card>

                        <NoIdeas className="d-block mx-auto my-3" style={{ height: '12rem' }} />

                        <p className="text-muted text-center">
                            No need to resort to socks and gift cards!
                        </p>
                    </Card.Body>
                </Card>
                <Card className="flex-grow-1" style={{flexBasis: 0}}>
                    <Card.Body>
                        <h2>Claim your friends' Wishes</h2>

                        <p>
                            When you browse your friends' wish lists, you can see which wishes are still up for grabs.
                            When you <b>Claim</b> a wish, nobody else can claim it, so you know you'll be the only one
                            giving them that gift!
                        </p>

                        <Card className="p-4 my-4" style={{ backgroundColor: 'rgb(0,0,0,0.01)', boxShadow: '0 0.25rem 0.5rem rgba(0,0,0,0.1) inset' }}>
                            <h3>John Smith</h3>
                            <ListGroup className="mb-3">
                                <ListGroup.Item>
                                    <Badge
                                        as="abbr"
                                        bg="secondary"
                                        text="white"
                                        className="float-end"
                                    >
                                        Taken
                                    </Badge>
                                    Video Games
                                </ListGroup.Item>
                            </ListGroup>
                            <h3>Jane Smith</h3>
                            <ListGroup className="mb-3">
                                <ListGroup.Item className="d-flex gap-3">
                                    <div className="flex-grow-1">
                                        Flower Vase
                                    </div>
                                    <ListGroup className="flex-grow-1">
                                        <ListGroup.Item variant="success">
                                            <div className="d-flex align-items-baseline">
                                                <small className="flex-grow-1"><b>Your gift:</b></small>
                                                <Badge
                                                    as="abbr"
                                                    bg="success"
                                                    text="white"
                                                >
                                                    <ClaimedWishIcon />
                                                    &nbsp;
                                                    Claimed!
                                                </Badge>
                                            </div>
                                            <Card.Title>Antique Flower Vase</Card.Title>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Badge
                                        as="abbr"
                                        bg="white"
                                        text="primary"
                                        className="border border-primary float-end"
                                    >
                                        <ClaimWishIcon />
                                        &nbsp;
                                        Claim
                                    </Badge>
                                    Boots
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>

                        <DuplicateGifts className="d-block mx-auto my-3" style={{ height: '12rem' }} />

                        <p className="text-muted text-center">
                            I knew I should have asked around...
                        </p>
                    </Card.Body>
                </Card>
            </div>

            <h1 className="mt-5">Isn't this just a gift registry?</h1>

            <p>
                Well, yes. But registries are operated by retailers who want you to buy from their store.
                This site isn't a retailer and it doesn't care <i>where</i> the gift came from.
            </p>

            <h1 className="mt-5">How do I get started?</h1>

            <p>Sign in to the link above.</p>

            <p>Then, create a <b>Group</b> and <b>Invite</b> your friends and family.</p>

            <h1 className="mt-5">Report issues</h1>

            <p>This project has a bug tracker.</p>

            <p>Send an email to <a href="mailto:contact-project+karlrwjohnson-wishing-well-mk2-30704169-issue-@incoming.gitlab.com">contact-project+karlrwjohnson-wishing-well-mk2-30704169-issue-@incoming.gitlab.com</a>.</p>

            <h1 className="mt-5">Who's behind this?</h1>

            <p>
                Just a software engineer who wanted a better way to share wish lists with friends and family.
            </p>

            <p>
                This is a hobby project with no plans for monetization.
                It may be taken down at any time with zero notice.
                Use at your own risk.
            </p>
        </>
    );
}
