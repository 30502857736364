import { useQuery } from '@apollo/client';
import { comparingBy } from '@karlrwjohnson/libkarl/esm/comparingBy.js';
import { listFriends } from '../queries/listFriends.js';

export function FriendOptions(
    {
        selectedUserId,
    }: {
        selectedUserId: string | null | undefined
    },
): JSX.Element {
    const friendsResult = useQuery(listFriends);

    const friends = friendsResult.data?.friends
            .slice()
            .sort(comparingBy(friend => `${friend.givenName} ${friend.surname}`))
        ?? [];

    if (selectedUserId && !friends.some(it => it.id === selectedUserId)) {
        friends.push(
            {
                id: selectedUserId,
                givenName: (friendsResult.loading ? '(loading)' : `User ${selectedUserId}`),
                surname: '',
            }
        )
    }

    const options = friends.map(friend =>
        <option key={friend.id} value={friend.id}>{friend.givenName} {friend.surname}</option>
    );

    return (
        <>
            <option className="text-muted" value="">(TBD)</option>
            {options}
        </>
    );
}
