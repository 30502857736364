import type { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { MainNav } from './MainNav.js';

export function App(
    {
        children
    }: {
        children: ReactNode
    }
): JSX.Element {
    return <>
        <MainNav />
        <Container className="mt-3 mb-5">
            {children}
        </Container>
        <Container className="text-muted text-center">
            <p>Copyright 2021 Karl Wingblade - <a href="mailto:contact-project+karlrwjohnson-wishing-well-mk2-30704169-issue-@incoming.gitlab.com">Contact</a></p>
        </Container>
    </>
}
