import type { FetchResult } from '@apollo/client';
import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import type { ReactNode } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { isLoggedIn, useAuthState } from './AuthProvider.js';
import { BACKEND_URL } from './constants.js';
import { deferRequestUntilWrappedLinkReady } from './utils/deferRequestUntilWrappedLinkReady.js';
import { fetchAndAlsoAttachOperationNameToUrl } from './utils/fetchAndAlsoAttachOperationNameToUrl.js';
import { Readiable } from './utils/Readiable.js';

export const CurrentRequestCountContext = createContext(0);

export function AppApolloProvider(
    {
        children,
    }: {
        children: ReactNode,
    },
): JSX.Element {
    const session = useAuthState();

    const httpLink: HttpLink | null = useMemo(() => {
        if (!isLoggedIn(session)) return null;
        
        return new HttpLink({
            uri: BACKEND_URL,
            headers: {
                Authorization: `Bearer ${session.id_token}`,
            },
            fetch: fetchAndAlsoAttachOperationNameToUrl,
        });
    }, [session]);

    const [readiableHttpLink] = useState<Readiable<HttpLink>>(Readiable.create);
    // Destroy on unmount
    useEffect(() => () => readiableHttpLink.destroy(), [readiableHttpLink]);
    if (httpLink) {
        readiableHttpLink.setReady(httpLink);
    } else {
        readiableHttpLink.setWaiting();
    }
    
    const [currentRequestCount, setCurrentRequestCount] = useState(0);

    const client = useMemo(() => {
        return new ApolloClient({
            link: from([
                // Update the current request count whenever a request begins/ends so the icon in the header
                // can become a loading indicator
                new ApolloLink((operation, forward) => {
                    setCurrentRequestCount(increment);

                    return new Observable(observer => {
                        const subscription = forward(operation).subscribe(
                            function onNext(value: FetchResult) { observer.next(value) },
                            function onError(error) {
                                subscription.unsubscribe();
                                setCurrentRequestCount(decrement);
                                observer.error(error);
                            },
                            function onComplete() {
                                subscription.unsubscribe();
                                setCurrentRequestCount(decrement);
                                observer.complete();
                            },
                        );
                    });
                }),
                deferRequestUntilWrappedLinkReady(readiableHttpLink),
            ]),
            cache: new InMemoryCache(),
        });
    }, [readiableHttpLink]);

    useEffect(() => () => client.stop(), [client])

    return (
        <ApolloProvider client={client}>
            <CurrentRequestCountContext.Provider value={currentRequestCount}>
                {children}
            </CurrentRequestCountContext.Provider>
        </ApolloProvider>
    );
}

function increment(prev: number): number {
    return prev + 1;
}

function decrement(prev: number): number {
    return prev - 1;
}
