// Defined by Webpack DefinePlugin
declare const WEBPACK_DEFINE_BACKEND_URL: string;
declare const WEBPACK_DEFINE_OAUTH_CLIENT_ID: string;
declare const WEBPACK_DEFINE_OAUTH_ISSUER: string;
declare const WEBPACK_DEFINE_PUBLIC_PATH: string;

export const BACKEND_URL = WEBPACK_DEFINE_BACKEND_URL;
export const OAUTH_CLIENT_ID = WEBPACK_DEFINE_OAUTH_CLIENT_ID;

export const OAUTH_ISSUER = WEBPACK_DEFINE_OAUTH_ISSUER;
export const PUBLIC_PATH = WEBPACK_DEFINE_PUBLIC_PATH;
