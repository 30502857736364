import { useQuery } from '@apollo/client';
import { preventDefault } from '@karlrwjohnson/libkarl/esm/react/DefaultPreventable.js';
import { Alert, Badge, Card, Image, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Outlet } from 'react-router';
import { generatePath, Link } from 'react-router-dom';
import { DraftWishIcon, EmptyWell, PlusIcon, WishlistIcon } from '../assets/index.js';
import { CREATE_WISH, EDIT_WISH } from '../paths.js';
import type { MyWish } from '../queries/getMyWishes.js';
import { getMyWishes } from '../queries/getMyWishes.js';

export function MyWishView(
    {
        myWish: wish,
    }: {
        myWish: MyWish
    }
): JSX.Element {

    return (
        <ListGroup.Item className="overflow-hidden" variant={wish.isDraft ? 'warning' : undefined}>
            <div
                className="float-end ms-3"
            >
                <Link
                    className="btn btn-outline-primary btn-sm"
                    to={generatePath(EDIT_WISH, { wishId: wish.id })}
                >
                    Edit Wish
                </Link>
            </div>

            <Card.Title className={wish.isDraft ? 'text-muted' : ''}>
                {wish.title}
            </Card.Title>

            {wish.isDraft &&
            <span className="fs-6">
                    <OverlayTrigger
                        overlay={<Tooltip>Drafts are invisible to others</Tooltip>}
                        placement="right"
                        trigger={['focus', 'hover']}
                    >
                        <Badge
                            as="abbr"
                            bg="warning"
                            text="dark"
                            className="mb-2"
                            onClick={preventDefault}
                            tabIndex={0}
                            data-bs-original-title="Drafts are invisible to others"
                        >
                            <DraftWishIcon /> Draft
                        </Badge>
                    </OverlayTrigger>
                </span>
            }

            {wish.description && <p style={{ whiteSpace: 'pre-wrap' }}>{wish.description}</p>}
            {wish.url && <p className="text-truncate"><a href={wish.url}>{wish.url}</a></p>}
            {wish.image && <p><Image alt="user-provided image" fluid src={wish.image} /></p>}
        </ListGroup.Item>
    );
}

export function MyWishlist(): JSX.Element {

    const myWishes = useQuery(getMyWishes, { initialFetchPolicy: 'cache-and-network', fetchPolicy: 'cache-and-network' });
    const totalWishCount = myWishes.data?.myWishes.length ?? 0;
    const draftWishCount = myWishes.data?.myWishes.filter(wish => wish.isDraft).length ?? 0;
    const publicWishCount = totalWishCount - draftWishCount;

    if (myWishes.loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div className="d-flex flex-row flex-wrap align-items-baseline">
                <h1 className="flex-grow-1">My Wishlist</h1>
                <div>
                    {/*{publicWishCount > 0 && <Badge bg="white" className="border border-primary" text="primary"><WishlistIcon /> {publicWishCount} public wish{publicWishCount > 1 ? 'es' : ''}</Badge>}*/}
                    {publicWishCount > 0 && <><WishlistIcon /> {publicWishCount} public wish{publicWishCount > 1 ? 'es' : ''}</>}
                    {draftWishCount > 0 && publicWishCount > 0 && '\u2003'}
                    {/*{draftWishCount > 0 && <Badge bg="warning" text="dark"><DraftWishIcon /> {draftWishCount} draft wish{draftWishCount > 1 ? 'es' : ''}</Badge>}*/}
                    {draftWishCount > 0 && <><DraftWishIcon /> {draftWishCount} draft wish{draftWishCount > 1 ? 'es' : ''}</>}
                </div>
            </div>
            <hr />

            {publicWishCount === 0 && totalWishCount > 0 &&
            <Alert variant="warning" className="mx-5">
            <Alert.Heading><DraftWishIcon /> All of your wishes are drafts!</Alert.Heading>
            <p>Wishes marked as "Draft" are invisible to your friends and family and cannot be claimed.</p>
            <p>When a wish is ready for viewing, edit the wish and un-check the "Draft" setting.</p>
            </Alert>
            }


            {(myWishes?.data?.myWishes.length ?? 0) > 0 ?
                <ListGroup className="mt-3 mb-5 shadow">
                    {myWishes?.data?.myWishes.map(wish => <MyWishView key={wish.id} myWish={wish} />)}
                </ListGroup> :
                <div className="text-center my-4">
                    <h3 className="mb-3">{`You don't have any wishes!`}</h3>
                    <p className="text-muted text-small fst-italic">
                        {`Don't get stuck with socks and gift cards`}
                    </p>
                    <p>
                        Give your friends and family a push in the right direction and click the button below:
                    </p>
                </div>
            }

            <Link
                className="btn btn-primary btn-lg mb-5 mt-3 mx-auto w-100 d-block position-sticky"
                style={{
                    bottom: '0.5rem',
                    boxShadow: '0 0 1rem rgba(0,0,0,0.4)',
                    maxWidth: '400px',
                    zIndex: 1,
                }}
                to={CREATE_WISH}
            >
                <PlusIcon /> Add a new wish
            </Link>

            {!(totalWishCount > 0) &&
            <EmptyWell
                className="my-5 mx-auto d-block"
                style={{
                    width: 229,
                    height: 342,
                }}
            /> }

            <Outlet />
        </>
    );
}
