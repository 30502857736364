import { useMutation } from '@apollo/client';
import { autofocus } from '@karlrwjohnson/libkarl/esm/react/autofocus.js';
import { transformState } from '@karlrwjohnson/libkarl/esm/transformBuilder/index.js';
import type { ChangeEvent, FormEvent, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { PeopleIcon } from '../assets/index.js';
import { ModalFormRoute, SubmitResultAction } from '../components/ModalFormRoute.js';
import { FRIEND_WISHLISTS } from '../paths.js';
import { createGroup } from '../mutations/createGroup.js';
import { getMyGroups } from '../queries/getMyGroups.js';

type FormState = {
    groupName: string;
};

export function CreateGroupModal(): ReactElement {
    const [doCreateGroup] = useMutation(createGroup);

    const [formState, setFormState] = useState<FormState>(() => {
        return {
            groupName: '',
        };
    });
    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

    const validateAndReturnValid = useCallback((): boolean => {
        const validationErrors: Record<string, string> = {};
        if (!formState.groupName) {
            validationErrors.groupName = 'This field is required';
        }
        setValidationErrors(validationErrors);

        return Object.entries(validationErrors).length === 0;
    }, [formState.groupName]);
    const handleAttemptSubmit = useCallback(async (
        setError: (error: string | null) => void
    ): Promise<SubmitResultAction> => {
        const isValid = validateAndReturnValid();
        if (!isValid) {
            setError('Fix the errors below and try again');
            return SubmitResultAction.STAY_OPEN;
        }

        const result = await doCreateGroup({
            refetchQueries: [getMyGroups],
            variables: {
                groupName: formState.groupName,
            }
        });

        const id = result.data?.createGroup?.id;
        if (!id) {
            if (result.errors && result.errors.length > 0) {
                const messages = result.errors.map(e => e.message).join('\n');
                setError('Error creating group:\n' + messages)
            } else {
                setError('Unknown error creating group')
            }
            return SubmitResultAction.STAY_OPEN;
        }

        // Redirect to the new group
        return SubmitResultAction.redirect(generatePath(FRIEND_WISHLISTS, { groupId: id }));
    }, [doCreateGroup, formState, validateAndReturnValid]);

    const handleChange = useCallback((evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | FormEvent<HTMLSelectElement>) => {
        const { id: key, value } = evt.currentTarget;
        console.log('change:', key, '=', value);
        switch (key) {
            case 'groupName':
                setFormState(prev => ({ ...prev, [key]: value }));
                break;
            default:
                return;
        }
        transformState(setValidationErrors).deleteKey(key).and.apply();
    }, [setFormState, setValidationErrors]);

    return (
        <ModalFormRoute
            actions={[
                {
                    icon: <PeopleIcon />,
                    label: 'Create Group',
                    onClick: handleAttemptSubmit,
                },
            ]}
            title="New Group"
        >
            <Form.Group
                className="my-3"
                controlId="groupName"
            >
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                    autoFocus
                    onChange={handleChange}
                    placeholder="Group Name"
                    ref={autofocus}
                    size="lg"
                    value={formState.groupName}
                />
                {validationErrors.groupName &&
                <Form.Text className="text-danger">{validationErrors.groupName}</Form.Text>
                }
            </Form.Group>
        </ModalFormRoute>
    );
}
