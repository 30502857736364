import { autofocus } from '@karlrwjohnson/libkarl/esm/react/autofocus.js';
import { transformState } from '@karlrwjohnson/libkarl/esm/transformBuilder/index.js';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import { Form } from 'react-bootstrap';

export type FormState = {
    title: string;
    description: string;
    url: string;
    image: string;
}

export type ValidationErrors = Record<string, string>;


export function WishForm(
    {
        formState,
        setFormState,
        setValidationErrors,
        validationErrors,
    }: {
        formState: FormState;
        setFormState: Dispatch<SetStateAction<FormState>>;
        setValidationErrors: Dispatch<SetStateAction<ValidationErrors>>;
        validationErrors: ValidationErrors;
    }
): JSX.Element {

    const handleChange = useCallback((evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const key = evt.target.id, value = evt.target.value;
        console.log('change:', key, '=', value);
        switch (key) {
            case 'title':
            case 'description':
            case 'url':
            case 'image':
                setFormState(prev => ({...prev, [key]: value}));
                setValidationErrors(prev => {
                    const next = {...prev};
                    delete next[key];
                    return next;
                });
                break;

            default:
                return;
        }
        transformState(setValidationErrors).deleteKey(key).and.apply();
    }, [setFormState, setValidationErrors]);

    return <>
        <Form.Group
            className="my-3"
            controlId="title"
        >
            <Form.Label>Title</Form.Label>
            <Form.Control
                autoFocus
                onChange={handleChange}
                placeholder="Title"
                ref={autofocus}
                size="lg"
                value={formState.title}
            />
            {validationErrors.title &&
            <Form.Text className="text-danger">{validationErrors.title}</Form.Text>
            }
        </Form.Group>

        <Form.Group
            className="my-3"
            controlId="description"
        >
            <Form.Label>Details</Form.Label>
            <Form.Control
                as="textarea"
                onChange={handleChange}
                placeholder="Additional details about this item"
                value={formState.description}
            />
            {validationErrors.description &&
            <Form.Text className="text-danger">{validationErrors.description}</Form.Text>
            }
        </Form.Group>
    
        <Form.Group
            className="my-3"
            controlId="url"
        >
            <Form.Label>Link</Form.Label>
            <Form.Control
                onChange={handleChange}
                placeholder="https://..."
                value={formState.url}
            />
            {validationErrors.url &&
            <Form.Text className="text-danger">{validationErrors.url}</Form.Text>
            }
        </Form.Group>
    
        <Form.Group
            className="my-3"
            controlId="image"
        >
            <Form.Label>Image</Form.Label>
            <Form.Control
                onChange={handleChange}
                placeholder="https://..."
                value={formState.image}
            />
            {validationErrors.image &&
            <Form.Text className="text-danger">{validationErrors.image}</Form.Text>
            }
        </Form.Group>
    </>;
}
